/* Main Sidebar Container */
.shortSidebar {
  position: fixed;
  width: 60px;
  height: 100vh;
  background-color: #051c3c;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  z-index: 999;
}
.shortSidebarTopIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}
.shortSidebarButtonIcons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.shortSidebarTopIcons div,
.shortSidebarButtonIcons div {
  transition: 0.1s;
}

.shortSidebarTopIcons div:hover,
.shortSidebarButtonIcons div:hover {
  background-color: #335c96;
  padding: 2px 3px;
  border-radius: 8px;
  transition: 0.1s;
}

.shortSidebar div img {
  width: 21px;
  height: 25px;
  background-size: cover;
  margin: auto;
}
/* arrow icon for open full sidebar  */
.shortSidebar_arrow-icon {
  position: absolute;
  right: -12px;
  background-color: #153563;
  color: white;
  border-radius: 100%;
  padding: 3px;
  font-weight: bold;
  top: 38px;
  /* transform: translateY(-50%); */
  cursor: pointer;
}

.dropdown-options-sidebar {
  position: absolute;
  width: 180px;
  max-height: 300px;
  background-color: #041d3c;
  box-shadow: 0 2px 8px rgba(165, 162, 162, 0.15);
  border: 2px solid rgb(240, 240, 240);
  border-radius: 5px;
  z-index: 1000;
  transition: opacity 0.2s ease;
  margin-left: 43px;
  margin-top: -30px;
  overflow-y: auto;
}
/* Custom Scrollbar for WebKit Browsers (Chrome, Safari, Edge) */

/* Dropdowns  */
.dropdown-options-sidebar::-webkit-scrollbar {
  width: 4px; /* Set scrollbar width */
}

.dropdown-options-sidebar::-webkit-scrollbar-track {
  background-color: #041d3c;
  border-radius: 10px; /* Rounded track */
}

.dropdown-options-sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 2px solid #f1f1f1;
}

.dropdown-option-sidebar {
  color: #d3d3d3;
  font-size: 13px;
  display: block; /* Full width for options */
  padding: 8px 15px; /* Padding for options */
  text-decoration: none; /* Remove underline */
}

.dropdown-option-sidebar:hover {
  color: rgb(241, 241, 241);
}

/* tooltips  --------------------- */
.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip-container .tooltip-text {
  visibility: hidden; /* Hidden by default */
  background-color: #041d3c;
  box-shadow: 0 2px 8px rgba(165, 162, 162, 0.15);
  color: #d3d3d3;
  border: 2px solid rgb(224, 224, 224);
  text-align: center;
  border-radius: 5px;
  padding: 3px 8px;
  font-weight: 400;
  position: absolute;
  z-index: 1;
  left: 46px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  font-size: 12px;
  transition: opacity 0.3s;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #bbbbbb;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
