html {
  overflow-x: hidden;
}

/* width */
*::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

/* Track */
*::-webkit-scrollbar-track {
  border-radius: 0;
  background: #edf2f7;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 0;
  border: 4px solid #edf2f7;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}

body {
  overflow: hidden !important;
}

.bodyoverflowunset {
  overflow: unset !important;
}

a {
  text-decoration: none;
}
.view-my-collection-button {
  font-weight: 600;
  padding: 8.3px;
  border: none;
  margin-left: 20px;
  outline: none;
  font-size: 13px;
  line-height: 21px;
  /* color: #175bcb !important; */
  color: white;
  border: 1px solid #175bcb;
  background-color: #205abc;
  border-radius: 5px;
}
.view-my-collection-button a {
  color: white;
}
.view-my-collection-button a:hover {
  color: white;
}
.primary-button {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #175bcb !important;
  border-radius: 5px !important;
}
.cancel-button {
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 21px !important;
  color: #000000 !important;
  border-color: #000000 !important;
  border-radius: 5px !important;
}
/* login   */
/* Wrapper for the entire component */
.main-component-signup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  /* padding: 0 20px; */
}
/* register css  */
.register-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-side-register {
  width: 1200px;
}
.image-side-register img {
  width: 650px;
  height: 100vh;
}
/* Top logo on the left */
.top-logo-signup-img {
  width: 230px;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

/* Container for the form */
.auth-container {
  width: 400px;
  margin: auto;
}

.register-page-left-image img {
  /* width: 1400px; */
}

/* register css end  */
/* Image inside the form section */
.auth-container div img {
  width: 75px;
  margin-bottom: 15px;
}

/* Center the form title and text */
.auth-container form h2 {
  font-weight: bold;
  margin-top: 17px;
  font-size: 24px;
  text-align: center;
}

.auth-container form p {
  text-align: center;
  margin-bottom: 20px;
}
.signup-content {
  border: 2px solid rgb(221, 221, 221);
  box-shadow: none;
}

/* Input fields styling */
.auth-container form .form-input {
  padding: 10px 8px;
  border-radius: 7px;
}

.auth-container form .form-input::placeholder {
  font-size: 16px;
}

/* Footer styling */
.footer-content {
  text-align: center;
  padding-bottom: 20px;
  font-size: 14px;
}

.footer-content a {
  color: #007bff;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

/* sidebar close condition  */
.page-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
}
.sidebar-open-page-wrap {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.child-one {
  width: 70px;
  z-index: 99;

  /* transition: transform 0.2s ease-in-out, width 0.2s ease-in-out; */
}

.sidebar-open-child-one {
  position: absolute;
  transform: translateX(0);
  transition: transform 0.2s ease-in-out, width 0.2s ease-in-out;
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5);
  width: 270px;
  background-color: #f6f8fb;
  z-index: 999;
}

.child-two {
  width: 100%;
  overflow-y: auto;
  background-color: #ffffff;
  /* z-index: 1; */
}

.float-child {
  /* width: 50%; */
  float: left;
}

.facg-main-wrapper input {
  padding: 5px 10px;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-shadow: none;
  max-width: 198px;
}

.facg-main-wrapper .ant-input-affix-wrapper {
  max-width: 198px;
  border-radius: 6px;
}

.facg-main-wrapper .ant-input-affix-wrapper input {
  background-color: transparent;
}

.save-collection-modal .ant-input-group-addon {
  display: none;
}

.save-collection-modal input[type="text"] {
  border-radius: 5px;
  box-shadow: 0px 0px 9px rgb(0 0 0 / 11%);
}

.cust-table .paginations {
  width: 36.5px;
  height: 36.5px;
  border: 2px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 10px;
  outline: none;
  margin: 0 10px;
  box-shadow: none;
}

.cust-table .paginations.active {
  background-color: rgb(23, 91, 203);
  border-color: rgb(23, 91, 203);
  color: #fff;
  box-shadow: none;
  outline: none;
}

.main-box {
  padding-left: 45px;
}

.main-box input {
  box-shadow: 0px 4px 13px rgb(0 0 0 / 11%);
  border-radius: 10px;
  border: none;
  height: 50px;
}

.main-box textarea {
  box-shadow: 0px 4px 13px rgb(0 0 0 / 11%);
  border-radius: 10px;
  border: none;
}

.mt-2 {
  margin-top: 10px;
}

.mt-2rem {
  margin-top: 2rem;
}

.box-shadow-cust-1 {
  height: 50px;
}

.form-group {
  margin: 30px 0 0;
}

.form-group input,
.form-group TextArea {
  border-radius: 5px;
}

.form-group label {
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}
.form-group label span {
  color: red;
}
.custom-text {
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 4px 15px;
  font-weight: 600;
  display: inline-block;
  min-width: 145px;
  max-width: 145px;
  text-align: center;
}

.fixed-center {
  position: fixed !important;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%,-50%); */
}

/* .facg-main-wrapper .ant-col{
  min-width: 212px;
} */
::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.content-wrapper {
  padding: 30px 80px 80px 80px;
}
.content-wrapper h3 {
  text-align: center;
  margin: 10px 0 40px 0;
  color: #2c80eb;
  font-weight: 600;
}

.fb_interest_list_page {
  margin: 2rem auto;
}

.fb_hidden_list {
  min-width: 100%;
  background-color: white;
  border: 0px;
  /* margin: 2rem auto; */
  height: 100%;
  overflow: auto;
}

.save_collection_keyword_search {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.save_collection_keyword_search button {
  border-radius: 10px;
  padding: 0.3rem 2rem;
  margin-top: 0rem;
}

.text-center {
  text-align: center;
}

.currency-wrapper {
  margin: 2rem 0;
}

.currency-label {
  margin-right: 2rem;
  font-weight: bold;
}

.searchfbInterest {
  text-align: center;
  margin: 50px auto;
  max-width: 700px;
}

.searchfbInterest input {
  border-radius: 8px 0px 0px 8px;
  border: 0px solid;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.11);
}

.searchfbInterest input:hover {
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.11);
}

.searchfbInterest.button {
  border-radius: 0px 10px 10px 0px !important;
  height: 38px !important;
}

.searchfbInterest span {
  border-radius: 10px;
}

.searchRadioButton {
  text-align: center;
  margin: 5px auto;
  max-width: 700px;
}

.collection-header {
  display: flex;
  justify-content: space-between;
}

.save_collection {
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: fit-content;
}

.save_collection button {
  border-radius: 5px;
  padding: 0.3rem 2rem;
  font-size: 16px;
  cursor: pointer;
}

.youtube_ref {
  width: 17px;
  height: 15px;
}

.white-box-wrapper {
  width: 80%;
  max-width: 800px;
  padding: 25px 40px;
  background-color: #fff;
  margin: 0 auto;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 4px 13px #00000008;
}

.char-counter {
  float: right;
  color: #979797;
}

.cust-table .react-bootstrap-table th {
  font-size: 14px;
  font-weight: 400;
}

.cust-table .react-bootstrap-table thead {
  background-color: #175bcb;
  color: #fff;
}

.cust-table .react-bootstrap-table tbody {
  border: none;
  background-color: #fff;
}

.cust-table .react-bootstrap-table tbody tr td {
  border-bottom: 1px solid #f0f0f0;
}

.cust-table .react-bootstrap-table tbody *:not(svg) {
  font-size: 14px;
}

.custom-facebook-report-button {
  background-color: #5890ff;
  color: white;
  text-align: center;
  margin-bottom: 40px;
  text-align: center;
  padding: 10px 20px 10px 20px;
}
.csv-download-button {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 2px;
  color: rgba(0, 0, 0, 0.85);
  border-color: #d9d9d9;
  background: #fff;
  margin-left: 20px;
}

.ant-list-header {
  background-color: #175bcb;
  color: white;
}

.table-header {
  display: flex;
  flex-flow: row wrap;
}

.display-table {
  width: 100%;
  display: table;
  border-collapse: collapse;
}

.table-tr {
  display: table-row;
  border-bottom: 1px solid #f0f0f0;
}

.table-td {
  display: table-cell;
  padding: 8px 16px;
}

.currency-dropdown {
  width: 220px;
  text-align: left;
}

.currency-dropdown-2 {
  margin-left: 10px;
  margin-top: 3px;
}

.link-style {
  font-size: 14px;
  color: #175bcb;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-statistic-content-value {
  word-break: break-all;
}

.search-input-container {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  height: 0vh; /* Make sure the container takes up the full viewport height */
}

.search-input-container > .text-input {
  padding: 10px;
  font-size: 14px;
  border: 1px solid grey;
  float: left;
  width: 65%;
  height: 40px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 8px 0px 0px 8px;
  border: 0px solid;
  box-shadow: 0px 4px 13px rgb(0 0 0 / 11%);
  background-color: #fff;
}

.search-input-container > .search-button {
  float: left;
  width: 10%;
  background: #2196f3;
  color: white;
  border: 1px solid grey;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;

  border-radius: 0px 8px 8px 0px !important;
  height: 38px !important;
  cursor: pointer;
  color: #fff;
  border-color: #175bcb;
  background: #175bcb;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.search-input-container .search-button:hover {
  background: #0b7dda;
}

.not-allowed-button {
  background: #bbbbbb !important;
  border: 1px solid #bbbbbb !important;
  cursor: not-allowed !important;
  outline: none !important;
  box-shadow: 0px 9px 13px rgb(0 0 0 / 11%) !important;
}

.stat-box-wrapper {
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 13px rgb(0 0 0 / 11%);
}

.stat-box-wrapper-col {
  padding: 30px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 13px rgb(0 0 0 / 11%);
  border: 1px solid #e2edfb;
  font-weight: 600;
}

.span-color span {
  color: #175bcb;
}

.red-zone-reached .ant-statistic-content-value {
  color: #e91e63 !important;
}

.yellow-zone-reached .ant-statistic-content-value {
  color: #ff9800 !important;
}

.green-zone-reached .ant-statistic-content-value {
  color: #009688 !important;
}

.suffix-small .ant-statistic-content-suffix {
  font-size: 14px;
}

.ant-statistic-title {
  color: #607d8b !important;
}

/* .ant-statistic-content {
  color: #175bcb !important;
} */
/* .color-bg-1 {
}

.color-bg-2 {
}

.color-bg-3 {
}

.color-bg-4 {
}

.color-bg-5 {
}

.color-bg-6 {
} */

.extra-pad-modal .ant-modal-content {
  padding: 20px 50px !important;
}

.pay-modal .ant-modal-header {
  border-radius: 10px 10px 0 0;
}

.StripeElement {
  border: 1px solid #ccc;
  padding: 7px;
  border-radius: 5px;
}

.editable-ai {
  float: right;
  margin-left: 5px;
}

.editable-ai span:hover {
  color: #175bcb;
}

.ant-modal-close {
  background-color: #175bcb;
}

.payment-input .err {
  color: #c9444d;
  font-size: 0.75rem;
  display: block;
}

.payment-input-err {
  color: #c9444d;
  font-size: 0.75rem;
  margin-bottom: 10px;
  display: block;
}

.ant-spin-dot.ant-spin-dot-spin {
  /* margin-top: 40vh !important; */
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
}

.ant-spin-text {
  position: fixed !important;
}

.ant-input[disabled] {
  color: #000000 !important;
}

.ant-input-affix-wrapper-disabled .ant-input-prefix {
  color: #000000 !important;
}

.video-modal .ant-modal-content {
  position: relative;
  background-color: unset;
  background-clip: unset;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.spinnerVideo .ant-spin-dot-item {
  background-color: #0578df;
}

body,
#root {
  height: 100%;
}
._main_app_container.auth,
.page-wrap {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -52px;
}
._main_app_container.auth:after,
.page-wrap:after {
  content: "";
  display: block;
}
.site-footer,
._main_app_container.auth:after,
.page-wrap:after {
  height: 52px;
}

.video-wrapper-dashboard {
  width: 534px;
  height: 300px;
}

.full-height {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-container {
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.facebook-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-items: center;
  padding: 20px 30px;
  width: 100%;
  flex-direction: row;
}
.facebook-grader-form {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px 0;
  border-radius: 8px;
  padding: 40px 30px;
}
.facebook-grader-form  h2{
  color: #0d6efd;
  font-size: 15px;
  font-weight: 500;
  text-align: start;
}
.facebook-grader-form Select{
  width:100%;
  padding:10px 0;
  text-align: start;
}
.date-picker-container {
  position: relative;
  margin-left: 40px;
  padding-top: 7px;
}

.facebook-background-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 50px;
  padding: 20px 0;
}
.facebook-background-container-charts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 50px;
  padding: 20px 0;
}

.facebook-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  margin-top: 3px;
  margin-left: 10px;
  width: 100%;
}

.facebook-logo-column-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-right: 10px;
  width: 100%;
  height: 100%;
}
.facebook-custom-background-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  padding: 10px;
  margin: 10px 20px 10px 20px;
}
.facebook-value-card-container {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  align-items: center;
  width: 250px;
  padding: 15px;
  height: 100%;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  background-color: white;
  border-radius: 10px;
}

.facebook-value-card-normal-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  margin: 5px 10px 5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.facebook-plane-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.facebook-plane-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.facebook-header-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px 10px 0px;
  flex-direction: row;
}
.date-picker-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 100vw; /* Ensure it fits within the viewport */
  overflow-x: auto;
}

.date-picker-dropdown .rdrDateRangePickerWrapper {
  width: 100%;
}

.google-login-button {
  background-color: #fff; /* Google brand color */
  color: black;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.google-login-button:hover {
  background-color: #fff;
}

.google-login-button:before {
  content: "";
  background-image: url("googleIcon.png"); /* Path to Google icon */
  background-size: cover;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}

/* From Uiverse.io by gustavofusco */
.pencil {
  display: block;
  width: 10em;
  height: 10em;
}

.pencil__body1,
.pencil__body2,
.pencil__body3,
.pencil__eraser,
.pencil__eraser-skew,
.pencil__point,
.pencil__rotate,
.pencil__stroke {
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.pencil__body1,
.pencil__body2,
.pencil__body3 {
  transform: rotate(-90deg);
}

.pencil__body1 {
  animation-name: pencilBody1;
}

.pencil__body2 {
  animation-name: pencilBody2;
}

.pencil__body3 {
  animation-name: pencilBody3;
}

.pencil__eraser {
  animation-name: pencilEraser;
  transform: rotate(-90deg) translate(49px, 0);
}

.pencil__eraser-skew {
  animation-name: pencilEraserSkew;
  animation-timing-function: ease-in-out;
}

.pencil__point {
  animation-name: pencilPoint;
  transform: rotate(-90deg) translate(49px, -30px);
}

.pencil__rotate {
  animation-name: pencilRotate;
}

.pencil__stroke {
  animation-name: pencilStroke;
  transform: translate(100px, 100px) rotate(-113deg);
}

/* Animations */
@keyframes pencilBody1 {
  from,
  to {
    stroke-dashoffset: 351.86;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 150.8;
    /* 3/8 of diameter */
    transform: rotate(-225deg);
  }
}

@keyframes pencilBody2 {
  from,
  to {
    stroke-dashoffset: 406.84;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 174.36;
    transform: rotate(-225deg);
  }
}

@keyframes pencilBody3 {
  from,
  to {
    stroke-dashoffset: 296.88;
    transform: rotate(-90deg);
  }

  50% {
    stroke-dashoffset: 127.23;
    transform: rotate(-225deg);
  }
}

@keyframes pencilEraser {
  from,
  to {
    transform: rotate(-45deg) translate(49px, 0);
  }

  50% {
    transform: rotate(0deg) translate(49px, 0);
  }
}

@keyframes pencilEraserSkew {
  from,
  32.5%,
  67.5%,
  to {
    transform: skewX(0);
  }

  35%,
  65% {
    transform: skewX(-4deg);
  }

  37.5%,
  62.5% {
    transform: skewX(8deg);
  }

  40%,
  45%,
  50%,
  55%,
  60% {
    transform: skewX(-15deg);
  }

  42.5%,
  47.5%,
  52.5%,
  57.5% {
    transform: skewX(15deg);
  }
}

@keyframes pencilPoint {
  from,
  to {
    transform: rotate(-90deg) translate(49px, -30px);
  }

  50% {
    transform: rotate(-225deg) translate(49px, -30px);
  }
}

@keyframes pencilRotate {
  from {
    transform: translate(100px, 100px) rotate(0);
  }

  to {
    transform: translate(100px, 100px) rotate(720deg);
  }
}

@keyframes pencilStroke {
  from {
    stroke-dashoffset: 439.82;
    transform: translate(100px, 100px) rotate(-113deg);
  }

  50% {
    stroke-dashoffset: 164.93;
    transform: translate(100px, 100px) rotate(-113deg);
  }

  75%,
  to {
    stroke-dashoffset: 439.82;
    transform: translate(100px, 100px) rotate(112deg);
  }
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .video-wrapper-dashboard {
    width: 534px;
    height: 300px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .video-wrapper-dashboard {
    width: 534px;
    height: 300px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .video-wrapper-dashboard {
    width: 534px;
    height: 300px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .video-wrapper-dashboard {
    width: 650px;
    height: 365px;
  }
}
@media only screen and (min-width: 1200px) {
  .video-wrapper-dashboard {
    width: 820px;
    height: 461px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) and (max-height: 700px) {
  .video-wrapper-dashboard {
    width: 489px;
    height: 275px;
  }
}
.button-container {
  text-align: right; /* Align the button to the right */
  margin-right: 12px;
}

.custom-gutter {
  row-gap: 2rem; /* Adds 2rem vertical spacing between rows */
}

.hover-container {
  border: 1px solid transparent; /* Default border */
  transition: border-color 0.3s ease; /* Smooth transition */
}

.hover-container:hover {
  border-color: #4885ed; /* Blue border on hover */
}

.custom-table td {
  font-size: 12px !important;
  font-weight: 400 !important;
}

.custom-tooltip {
  position: absolute;
  background-color: #333; /* Dark background for the tooltip */
  color: #fff; /* White text color */
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  visibility: hidden; /* Hidden by default */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-tooltip.show {
  visibility: visible;
  opacity: 1;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}
