.radio_button_div {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: serif;
    font-size:16px;
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.radio_button {
    margin-right: 1em;
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 501;
    display: inline-flex;
    align-items: center;
    color: black;
}

.radio_label{
    padding-left: 1em;
    padding-right: 1em;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    color: black;
}