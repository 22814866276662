.logo {
  width: 180px;
}
.sidebarlogo {
  width: 200px;
}
.flex {
  display: flex;
  align-items: center;
}
