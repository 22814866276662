.ant-modal-close-x {
    height: 35px !important;
    line-height: 35px !important;
}



.explore-title {
    font-size: 20px;
    margin-top: 5px;
}

.ant-input-wrapper {
    border-radius: 10px !important;
}

.ant-input-search-large .ant-input-search-button {
    border-radius: 0px 8px 8px 0px !important;
    height: 38px !important;
}

.ant-modal-body {
    padding: 0 !important;
}

.ant-modal-content {
    border-radius: 10px !important;
}

.modal_header {
    font-size: 20px;
    background-color: #175BCB;
    padding: 0.5rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 10px 0 0;
}

.modal_content {
    padding: 2rem;
}

.modal_content h3 {
    font-weight: 600;
    font-size: 1.5rem;
}

.modal-actions {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
}

.modal-actions button {
    border-radius: 10px;
}

.select-layers-box {
    background: #FFFFFF;
    height: 120px;
    margin-top: 10px;
    border: 1px solid #f3f3f3;
    padding: 15px;
    margin-bottom: 30px;
    width: 100%;
    border-radius: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.ant-modal-close-x svg {
    color: #fff;
}

.ant-modal-close-x svg:hover {
    color: #000;
}

.modal_content h6 u {
    text-decoration: underline;
}

.fb_hidden_list_collection {
    width: 60vw;
    min-width: 100%;
    margin: auto;
    margin-top: 2rem;
    background-color: white;
    border: 0px;
}

.react-tag-input {
    /* min-height: 100px !important; */
    margin-top: 10px;
}

.layer-link {
    text-decoration: underline;
    cursor: pointer;
}

.clipboard-icon {
    color: #175BCB;
    cursor: pointer;
    vertical-align: middle;
    font-size: 12px
}

.color-green {
    color: #4caf50;
}

.right-align {
    text-align: right;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
    content: "";
}

.add-layer-btn {
    color: #175BCB;
    cursor: pointer;
}