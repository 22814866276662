.pagination-wrapper {
  display: flex;
}

.ml-10 {
  margin-left: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.my-1 {
  margin: 10px 0;
}

.no-hover {
  color: #262626 !important;
}

.no-hover:hover {
  color: #262626;
  box-shadow: none;
}

.pricing_country_section {
  margin-top: 2rem;
}

._target_keyword_result {
  margin: auto;
  background-color: white;
  margin-top: 1rem;
  padding: 1rem 2rem;
  box-shadow: 1px 2px 3px #d3cccc;
}

._target_keyword_result h4 {
  color: black;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 20px;
}

._target_keyword_header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

._target_keyword_header h6 {
  font-size: 0.85rem;
  color: black;
  font-weight: 600;
}

.currency_country {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  width: 20rem;
}

.ant-modal-close-x {
  height: 35px !important;
  line-height: 35px !important;
}

.currency_country label {
  font-size: 1rem;
  font-weight: 600;
}

.ant-input-wrapper {
  border-radius: 10px !important;
}

.ant-input-search-large .ant-input-search-button {
  border-radius: 0px 8px 8px 0px !important;
  height: 38px !important;
}

.search-button-seperate {
  background: #2196f3;
  color: white;
  border: 1px solid grey;
  cursor: pointer;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;

  border-radius: 0px 8px 8px 0px !important;
  height: 38px !important;
  cursor: pointer;
  color: #fff;
  border-color: #175bcb;
  background: #175bcb;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-modal-body {
  padding: 0 !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

.modal_header {
  background-color: #175bcb;
  padding: 0.5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
}

.modal_content {
  padding: 2rem;
}

.modal_content h3 {
  font-weight: 600;
  font-size: 1.5rem;
}

.modal-actions {
  width: 100%;
  text-align: center;
  margin-top: 2rem;
}

.modal-actions button {
  border-radius: 10px;
}

.antd-chart-cust-1 {
  background-color: white;
  padding: 40px;
  /* height: 300px; */
}

.card {
  /* height: auto; */
  box-shadow: rgb(0 0 0 / 20%) 4px 4px 15px;
  border-radius: 8px;
  padding: 1rem 1rem 2rem;
  /* padding-bottom: 50px; */
  background-color: white;
  /* -webkit-box-flex: 1;
  flex-grow: 1;
  margin: 0px 1rem;
  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  font-size: 1rem; */
}

.video {
  border-radius: 8px;
  padding: 1rem 1rem 2rem;
  -webkit-box-flex: 1;
  flex-grow: 1;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}

.videodown {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}

th {
  background: #333;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: #fff;
  font-size: 1.2rem;
}

td,
th {
  border: 1px solid #ddd;
  text-align: left;
  padding: 8px;
  font-size: 1.1rem;
}

td,
th {
  padding: 0.25rem;
}

.tag-cust {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum", "tnum";
  display: inline-block;
  height: auto;
  margin: 0 8px 0 0;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin: 1rem 0.5rem 0rem;
}

.card-1 {
  border: 1px solid #ccc;
  background: white;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 20%) 4px 4px 15px;
  padding: 20px;
}

.like-view {
  display: flex;
  justify-content: space-between;
}

.flex-div {
  display: flex;
  flex-grow: 50%;
  gap: 10px;
}

.box-1 .title,
.main-title {
  font-size: 16px;
  color: black;
}

.goback {
  font-size: 18px;
  color: #175bcb;
  cursor: pointer;
}

.star-posi {
  position: absolute;
  top: 0px;
  right: 17px;
}

.view-my-collection {
  color: #8f8181;
  cursor: pointer;
  position: absolute;
  right: 80px;
  font-size: 13px;
  top: -60px;
  background: #175bcb;
  padding: 9px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
}

.dropdownheight ul {
  max-height: 35vh;
  overflow-y: scroll;
}

.adLine {
  font-size: 14px;
  margin-bottom: 3px;
}
