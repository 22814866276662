/* @extend display-flex; */

display-flex,
.display-flex,
.display-flex-center {
  display: flex;
  display: -webkit-flex;
}

/* @extend list-type-ulli; */

list-type-ulli {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Montserrat-300 - latin */

a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}

input[type="checkbox"] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}

input[type="radio"] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
}

p {
  margin-bottom: 0px;
  font-size: 15px;
  color: #000000;
}

.form-title {
  text-align: left;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 23px;
}

.clear {
  clear: both;
}

.container {
  width: 430px;
  position: relative;
  margin: 0 auto;
}

.display-flex {
  justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.display-flex-center {
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.signup-content {
  background: #fff;
  border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.signup-content .form-group {
  overflow: hidden;
  margin-bottom: 25px;
  margin-top: 0;
}

.form-input {
  width: 100%;
  border: 2px solid #ccc !important;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 500;
  color: #222;
}

.form-input::-webkit-input-placeholder {
  color: #999;
}

.form-input::-moz-placeholder {
  color: #999;
}

.form-input:-ms-input-placeholder {
  color: #999;
}

.form-input:-moz-placeholder {
  color: #999;
}

.form-input::-webkit-input-placeholder {
  font-weight: 500;
}

.form-input::-moz-placeholder {
  font-weight: 500;
}

.form-input:-ms-input-placeholder {
  font-weight: 500;
}

.form-input:-moz-placeholder {
  font-weight: 500;
}

.form-input:focus {
  border: 1px solid transparent;
  -webkit-border-image-source: -webkit-linear-gradient(
    to right,
    #9face6,
    #74ebd5
  );
  -moz-border-image-source: -moz-linear-gradient(to right, #9face6, #74ebd5);
  -o-border-image-source: -o-linear-gradient(to right, #9face6, #74ebd5);
  border-image-source: linear-gradient(to right, #9face6, #74ebd5);
  -webkit-border-image-slice: 1;
  border-image-slice: 1;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.form-input:focus::-webkit-input-placeholder {
  color: #222;
}

.form-input:focus::-moz-placeholder {
  color: #222;
}

.form-input:focus:-ms-input-placeholder {
  color: #222;
}

.form-input:focus:-moz-placeholder {
  color: #222;
}

.form-submit {
  width: 100%;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  padding: 17px 20px;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  border: none;
  background-image: -moz-linear-gradient(to left, #74ebd5, #9face6);
  background-image: -ms-linear-gradient(to left, #74ebd5, #9face6);
  background-image: -o-linear-gradient(to left, #74ebd5, #9face6);
  background-image: -webkit-linear-gradient(to left, #74ebd5, #9face6);
  background-image: linear-gradient(to left, #74ebd5, #9face6);
}
/* 
input[type=checkbox]:not(old) {
    width: 2em;
    margin: 0;
    padding: 0;
    font-size: 1em;
    display: none;
}

input[type=checkbox]:not(old)+label>span {
    display: inline-block;
    width: 13px;
    height: 13px;
    margin-right: 15px;
    margin-bottom: 3px;
    border: 1px solid #ebebeb;
    border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    -o-border-radius: 2px;
    -ms-border-radius: 2px;
    background: white;
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white);
    vertical-align: bottom;
}

input[type=checkbox]:not(old):checked+label>span {
    background-image: -moz-linear-gradient(white, white);
    background-image: -ms-linear-gradient(white, white);
    background-image: -o-linear-gradient(white, white);
    background-image: -webkit-linear-gradient(white, white);
    background-image: linear-gradient(white, white);
}

input[type=checkbox]:not(old):checked+label>span:before {
    content: '\f26b';
    display: block;
    color: #222;
    font-size: 11px;
    line-height: 1.2;
    text-align: center;
    font-family: 'Material-Design-Iconic-Font';
    font-weight: bold;
} */

.agree-term-check {
  margin-top: 5px;
  margin-right: 15px;
}
.label-agree-term {
  font-size: 12px;
  font-weight: 600;
  color: #555;
}

.term-service {
  color: #666;
  margin-left: 4px;
  cursor: pointer;
}

.invalid-feedback {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.loginhere {
  color: #555;
  font-weight: 500;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 5px;
}

.loginhere-link {
  font-weight: 700;
  color: #222;
}

.field-icon {
  float: right;
  margin-right: 17px;
  margin-top: -32px;
  position: relative;
  z-index: 2;
  color: #555;
}

.signup-content .form-group label {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
}

@media only screen and (min-height: 600px) {
  ._main_app_container.auth {
    height: 100vh;
    padding: 0px;
    width: 100%;
    padding-top: 6%;
  }
}

@media screen and (max-height: 700px) {
  ._main_app_container.auth.register {
    padding-top: 30px;
  }
  .register .form-title {
    margin-bottom: 5px;
  }
  .register .form-group {
    margin-bottom: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: calc(100% - 40px);
    max-width: 100%;
  }

  ._main_app_container.auth {
    padding-top: 40px;
  }
}

@media screen and (max-width: 480px) {
  .signup-content {
    padding: 50px 25px;
  }
  ._main_app_container.auth {
    padding-top: 40px;
  }
}

.fw-600 {
  font-weight: 600;
}

.text-semibold {
  font-weight: 500;
}

.auth-logo {
  width: 250px !important;
}

.forget-link {
  color: #2f80ed !important;
  font-weight: 600;
}
