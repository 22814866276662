.ant-drawer-content-wrapper {
    width: 400px !important;
}

.notification .notification-desc-block {
    background-color: #f5f5f5;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.notification .notification-title {
    padding: 10px 0 0 0;
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: bold;
    font-size: 14px !important;
    line-height: 10px;
    letter-spacing: 0.464286px;
    color: #2F80ED;
}

.notification .notification-desc {
    font-size: 13px !important;
    line-height: 20px;
    letter-spacing: 0.53px;
    color: #000000;
    margin: 0;
}

.notification .notification-subtitle {
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: 0.53px;
    color: #697E8D;
    margin-bottom: 0;
}