.adImageAnalyzer {
  padding: 20px;
}
.uploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.uploadBox {
  width: 700px;
  height: 300px;
  border: 2px dashed #ddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  transition: border-color 0.3s ease;
}

.uploadBox:hover {
  border-color: #aaa;
}

.fileInput {
  display: none;
}

.label {
  text-align: center;
  cursor: pointer;
  color: #555;
}

.uploadIcon {
  font-size: 24px;
  color: #888;
  width: 70px;
  margin-bottom: 10px;
}

.fileType {
  font-size: 12px;
  color: #999;
}

.heading {
  text-align: center;
  color: #252525;
  margin-top: -20px;
  margin-bottom: 50px;
}
.filename {
  font-weight: 600;
}
.heatmapbtn {
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
}
.heatmapbtn button {
  margin: auto;
  width: 700px;
  height: 40px;
  font-weight: 600;
  font-size: 15px;
  background-color: #185aca;
  color: white;
  border: none;
  outline: none;
  margin-top: 20px;
  border-radius: 8px;
}
.heatmapbtn button:hover {
  background-color: #1761df;
}

.container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px;
  color: #ffffff;
}

.heatmapSection {
  flex: 3;
  margin-right: 20px;
}
.heatmapSection h2 {
  color: #216fd8;
  font-weight: bold;
}

.sliderContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.sliderContainer label {
  color: #216fd8;
}
.sliderContainer span {
  color: #216fd8;
}

.slider {
  background-color: rgb(189, 189, 189);
  margin: 0 10px;
  flex: 1;
  border-radius: 20px;
}

.heatmap {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #2a2a2a; */
  border-radius: 8px;
  padding: 10px;
}

.heatmapImage {
  max-width: 100%;
  border-radius: 8px;
}

.metricsSection {
  flex: 1;
  padding: 20px;
  border-radius: 8px;
}

.metricsSection h3 {
  color: #216fd8;
  font-weight: bold;
}
.metrics {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.metrics div {
  display: flex;
  justify-content: space-between;
  background-color: #cdcdcd;
  padding: 10px;
  border-radius: 8px;
}

.metrics div p {
  margin: 0;
  font-weight: bold;
}

.metrics div span {
  font-weight: bold;
  font-size: 1.2em;
  color: #216fd8;
}
