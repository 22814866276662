.meta-card-top-container {
  background-color: #f0f2f5; /* Light background to highlight the cards */
  height: 100vh;
}
.meta-card-header-name {
  padding: 30px 0;
}
.meta-card-center {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap;
  cursor: pointer;
}
.meta-card-center img {
  width: 370px;
}
.meta-ads-card {
  width: 300px;
  background-color: #f7f7f7;
  border-radius: 12px;
  padding: 16px;
  /* margin: auto; */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.card-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  gap: 8px;
  position: relative;
}

.data-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
}

.red-box {
  background-color: #fbe5e5;
  color: #e74c3c;
}

.green-box {
  background-color: #e6f7e6;
  color: #27ae60;
}

.chart-box {
  grid-column: span 3;
  background-color: #e6f2ff;
  border-radius: 8px;
  padding: 8px;
}

.table-placeholder {
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.table-rows {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.table-row {
  height: 6px;
  background-color: #ddd;
  border-radius: 4px;
}

.new-badge {
  position: absolute;
  bottom: -12px;
  left: 16px;
  background-color: #27ae60;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: bold;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  font-size: 14px;
  color: #333;
}

.facebook-icon img {
  margin-bottom: 4px;
  width: 20px;
}
