.no-Scroll {
  max-height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.menu-icon-opensidebar {
  /* border: 2px solid red; */
  margin-bottom: 10px;

  margin-left: 24px;
}
.white {
  background-color: aqua;
  background-repeat: repeat-y;
}

.dashboard {
  color: #175bcb;
}

.ant-menu-title-content {
  word-wrap: break-word !important;
}

.ant-col-5 {
  max-width: max-content !important;
}

.sidebar {
  max-height: 100vh;
  overflow-y: scroll;
  transition: transform 0.3s ease;
}
.collapsed {
  width: 80px; /* Width when collapsed */
}

.expanded {
  width: 250px; /* Full width when expanded */
}
.custom-menu .ant-menu-submenu-title,
.custom-menu .ant-menu-item {
  display: flex;
  align-items: center;
}

.custom-menu .ant-menu-submenu-title span,
.custom-menu .ant-menu-item span {
  display: inline-block;
  transition: display 0.3s ease;
}
/*custom scroll bar*/

/* width */
.sidebar::-webkit-scrollbar {
  width: 2px;
  height: 6px;
}

/* Track */
.sidebar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
.sidebar::-webkit-scrollbar-thumb:hover {
  background: #a0aec0;
}
ul .ant-menu-item-selected a:hover,
ul .ant-menu-item-selected a {
  color: #000;
  font-weight: 600;
}

.custom-menu {
  transition: ease-in-out 0.2s;
  scrollbar-width: thin; /* Firefox */
  user-select: none;
  scrollbar-color: #ffffff #ffffff;
}

.custom-menu::-webkit-scrollbar {
  width: 4px; /* width of the scrollbar */
}

.custom-menu::-webkit-scrollbar-track {
  background: #ffffff; /* track color */
}

.custom-menu::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* thumb color */
  border-radius: 30px; /* rounded corners */
  border: 2px solid #ffffff; /* space around thumb */
}

.custom-menu::-webkit-scrollbar-thumb:hover {
  background: #ffffff; /* thumb hover color */
}
.fullSidebar_arrow-icon {
  position: absolute;
  right: -12px;
  background-color: rgb(255, 255, 255);
  color: black;
  border-radius: 100%;
  padding: 3px;
  font-weight: bold;
  top: 38px;
  /* transform: translateY(-50%); */
  cursor: pointer;
}
.custom-menu span {
  white-space: break-spaces;
  line-height: normal;
  font-size: 12px;
}
.grey-out svg,
.grey-out:hover {
  margin-right: 10px;
}

.grey-out .ant-menu-title-content {
  color: #9e9e9e;
}

.ml10a a {
  margin-left: 10px !important;
}
.ml10a {
}
.ml10 {
  margin-left: 10px !important;
}

.pl24 {
  padding-left: 24px !important;
  padding-right: 34px !important;
}

.pl48 {
  padding-left: 48px !important;
  padding-right: 16px !important;
}
.cust-box-1 {
  position: relative;
  width: calc(100% - 48px);
  height: 160px;
  margin: 10px auto;
  padding: 23px;
  background: #175bcb;
  border-radius: 10px;
}
.box-label {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 27px !important;
  color: white !important;
  white-space: nowrap !important;
}
.learn-more-1 {
  display: block;
  position: absolute;
  bottom: 30px;
  z-index: 2;
}
.learn-more-1 a {
  cursor: pointer;
  display: block;
  box-shadow: rgba(70, 22, 2, 0.26) 0px 6px 20px;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  padding: 10px 35px 10px 20px;
}
.label-cust-1 {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  display: inline;
  align-items: center;
  text-align: center;
  color: rgb(23, 27, 30);
}
.ic-chevron-svg {
  position: absolute;
  bottom: 8px;
  padding: 0px 5px;
}
.circles-svg {
  position: absolute;
  bottom: 24px;
  display: inline;
  left: 13px;
  z-index: 1;
}
.wave-container {
  display: inline;
  position: absolute;
  right: 10px;
  top: 10px;
}
img.wave-svg {
  display: block;
}
