.table-card {
  border: none;
  padding: 15px;
}

.table-scroll-wrapper {
  max-height: 400px; /* Limit table height */
  overflow-y: auto; /* Enable scrolling */
}

.custom-table .table,
.custom-table .table th,
.custom-table .table td {
  border: none !important; /* Remove all borders */
  border-collapse: collapse; /* Collapse cell spacing */
  box-shadow: none !important; /* Remove shadows */
}

.custom-table .table thead th {
  border-bottom: none !important; /* Remove bottom border on header */
}

/* Ensure no bottom border appears on hover */
.custom-table .table-hover tbody tr:hover {
  background-color: #f8f9fa;
  border: none !important;
}

div.table-scroll-wrapper .custom-table .table,
div.table-scroll-wrapper .custom-table .table th,
div.table-scroll-wrapper .custom-table .table td {
  border: none !important;
  border-collapse: collapse;
  box-shadow: none !important;
}   